<template>
  <div
    class="grid p-4"
    style="
      background: linear-gradient(90deg, #7F9F33 0%, #405B01 98.79%) !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      place-items: center;
    "
  >
    <div class="p-2" style="display: flex; align-items: center;">
      <div class="cardd">
        <Image src="/images/cultureIcon.svg" alt="Image" width="35" />
      </div>
      <div>
        <div style="display: flex; align-items: center">
          <p class="response">Cultura</p>
          <span class="response" style="padding-top: 3px; padding-left: 7px">
            Milho
          </span>
        </div>
        <div style="display: flex; align-items: center">
          <p class="response">Safra</p>
          <span class="response" style="padding-top: 3px; padding-left: 20px"
            >2022/2023</span
          >
        </div>
      </div>
    </div>

    <div class="p-2" style="display: flex; align-items: center;">
      <div class="cardd">
        <Image src="/images/productIcon.svg" alt="Image" width="35" />
      </div>
      <div>
        <div style="display: flex; align-items: center">
          <p class="response">Produto Avaliado</p>
          <span class="response" style="padding-top: 3px; padding-left: 9px"
            >XYTZ</span
          >
        </div>
        <div style="display: flex; align-items: center">
          <p class="response">Dose Aplicada</p>
          <span class="response" style="padding-top: 3px; padding-left: 27px"
            >30mg/L</span
          >
        </div>
      </div>
    </div>

    <div class="p-2" style="display: flex; align-items: center;">
      <div class="cardd">
        <Image src="/images/regIcon.svg" alt="Image" width="35" />
      </div>
      <div>
        <div style="display: flex; align-items: center">
          <p class="response">Estágio(s)</p>
          <div v-if="typeProject === '01'">
            <span class="response" style="padding-top: 3px; padding-left: 9px"
              >Vegetativo e Reprodutivo</span
            >
          </div>
          <div v-else>
            <span class="response" style="padding-top: 3px; padding-left: 9px"
              >Vegetativo</span
            >
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <p class="response">Regiões</p>
          <span class="response" style="padding-top: 3px; padding-left: 22px">{{
            dataProject.regioes
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { typeProject } from "@/service/TypeProject.js";

export default {
  name: "ProjectInfo",
  data() {
    return {
      typeProject,
      dataProject: {
        cultura: "Soja",
        safra: "2022/2023",
        produtoAvaliado: "XYTZ",
        doseAplicada: "30mg/L",
        regioes: "02",
      },
    };
  },
};
</script>

<style>
.cardd {
  width: 53px;
  height: 55px;
  background: #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0px 15px;
  margin-left: 10px;
  display: flex;
  place-items: center;
  padding-left: 8px;
}

.response {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
}
</style>
